
import { Options, Vue } from 'vue-class-component';
import { BagEstimationClient, CompanyClient, EstimationClient, FabricEstimationClient, FootwearEstimationClient } from '@/services/Services';
import { ExportServices } from '@/services/ExportServices';
import { baseUrl } from '@/config';
import InfoModal from '@/components/modals/infoModal.vue';
import store from '@/store';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import UploadExcelModal from '../modals/uploadExcelModal.vue';

@Options({
    components: {}
})
export default class dashboard extends Vue {

    estimations: OM.EstimationListVm[] = [];

    isManager: boolean = false;
    roles = VM.Roles;

    created() {
        this.isManager = store.state.loggedUser.role == this.roles.CompanyManager;
        this.init();
    }

    init() {
        if(this.isManager) {
            EstimationClient.getEstimationsByCompany(store.state.loggedUser.company.identifier)
            .then(x => {
                this.estimations = x;
            })
        }
        else {
            EstimationClient.getEstimationsByCustomer(store.state.loggedUser.identifier)
            .then(x => {
                this.estimations = x;
            })
        }
    }

    editEstimation(item: OM.EstimationListVm) {
        if(item.isFootwear)
            this.editEstimationRedirect(FootwearEstimationClient, item.identifier, item.finishedOn, true, false, false);
        else if(item.isBag)
            this.editEstimationRedirect(BagEstimationClient, item.identifier, item.finishedOn, false, false, true);
        else if(item.isFabric)
            this.editEstimationRedirect(FabricEstimationClient, item.identifier, item.finishedOn, false, true, false);
        else
            this.editEstimationRedirect(EstimationClient, item.identifier, item.finishedOn, false, false, false);
    }

    editEstimationRedirect(client: any, estimationIdentifier: string, finishedOn: string, isFootwear: boolean, isFabric: boolean, isBag: boolean) {
        client.checkIfCanEditEstimationByLicenseAndUseLicense(estimationIdentifier)
        .then(x => {
            if(finishedOn)
                store.state.estimationIdentifier = estimationIdentifier;
        
            if(isFootwear)
                this.$router.push('/footwear-calculator/components-weights/' + estimationIdentifier);
            else if(isBag)
                this.$router.push('/bag-calculator/components-weights/' + estimationIdentifier);
            else if(isFabric) {
                Promise.all([
                    FabricEstimationClient.checkIfHasFarming(estimationIdentifier),
                    FabricEstimationClient.checkIfHasWool(estimationIdentifier)
                ])
                .then(xs => {
                    if(xs[0])
                        this.$router.push('/fabric-calculator/farming/' + estimationIdentifier);
                    else if(xs[1])
                        this.$router.push('/fabric-calculator/wool/' + estimationIdentifier);
                    else
                        this.$router.push('/fabric-calculator/rawmaterials/' + estimationIdentifier);
                })
            }
            else
                this.$router.push('/calculator/editbaseinformation/' + estimationIdentifier);

            store.state.updateLicenses = store.state.updateLicenses++;
        })
        .catch(y => {
            var estimationGarmentName = this.estimations.find(x => x.identifier == estimationIdentifier).garment.text;
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("Error", "Error"),
                subtitle: this.$localizationService.getLocalizedValue(estimationGarmentName + ": " + y, estimationGarmentName + ": " + y),
                deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

    downloadPDF(item: OM.EstimationListVm) {
        if(item.isFootwear) {
            FootwearEstimationClient.getPDFName(item.identifier)
            .then(x => {
                this.$utils.downloadPdf(baseUrl + "api/FootwearEstimation/DownloadPDF", item.identifier, x);
            })
        }
        else if(item.isBag) {
            BagEstimationClient.getPDFName(item.identifier)
            .then(x => {
                this.$utils.downloadPdf(baseUrl + "api/BagEstimation/DownloadPDF", item.identifier, x);
            })
        }
        else {
            EstimationClient.getPDFName(item.identifier)
            .then(x => {
                if(item.isFabric)
                    this.$utils.downloadPdf(baseUrl + "api/FabricEstimation/DownloadPDF", item.identifier, x);
                else
                    this.$utils.downloadPdf(baseUrl + "api/Estimation/DownloadPDF", item.identifier, x);
            })
        }
    }

    exportCsv(item: OM.EstimationListVm) {
        if(item.isFootwear) {
            FootwearEstimationClient.exportCsv(item.identifier)
            .then(x => {
                ExportServices.DownloadExport(x.identifier, x.text);
            })
        }
        else if(item.isBag) {
            BagEstimationClient.exportCsv(item.identifier)
            .then(x => {
                ExportServices.DownloadExport(x.identifier, x.text);
            })
        }
        else {
            EstimationClient.exportCsv(item.identifier)
            .then(x => {
                ExportServices.DownloadExport(x.identifier, x.text);
            })
        }
    }

    bestCombination(item: OM.EstimationListVm) {
        if(item.isFootwear)
            this.$router.push('/best-footwear-combination/' + item.identifier);
        else if(item.isBag)
            this.$router.push('/best-bag-combination/' + item.identifier);
        else
            this.$router.push('/eco-pilot/' + item.identifier);
    }

    get testUser() {
        var loggedUser = store.state.loggedUser;

        var show = false;
        if(loggedUser.username.indexOf("edmond") > -1 || loggedUser.username.indexOf("nike") > -1)
            show = true;

        return show;
    }

    uploadExcelModal() {
        this.$opModal.show(UploadExcelModal, {
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

}
